import React, { Component } from 'react'
import Home from './pages/Home'

class App extends Component {
  render () {
    return <Home />
  }
}

export default App
